* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif; }

body {
  margin: 0;
  padding: 0;
  color: #fff; }

a,
button {
  outline: none;
  text-decoration: none; }

h1,
h2 {
  color: #373b3c;
  font-size: 28px;
  position: relative; }

.heading-line {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 2px;
    background-color: #373b3c;
    top: 50%;
    left: -60px; } }

.button-slider {
  padding: 15px 70px;
  color: #fff;
  font-size: 22px;
  border: 1px solid #93a2bc;
  transition: all 0.4s;
  &:hover {
    color: #93a2bc; } }

.content {
  width: 100%;
  max-width: 1440px;
  margin: 50px auto; }


.main-slider-item_info-license-title {
  font-size: 16px;
  color: #fff;
  border-bottom: 2px solid #fff;
  max-width: 225px;
  text-align: center;
  padding-bottom: 6px; }

.main-slider-item_info-license-desc {
  color: #fff;
  font-size: 20px;
  font-weight: 700; }

.main-slider-item_info-license-sert {
  color: #fff;
  font-size: 16px; }

.main-slider-item_btn-more {
  color: #92aedd;
  font-size: 16px; }

.main-slider-item img {
  width: 100%; }

.main-slider-item {
  position: relative;
  outline: none;
  height: 100vh !important; }

.main-slider-item_info {
  position: absolute;
  top: 30%;
  left: 12%; }

.main-slider-item_info-head {
  max-width: 670px;
  margin: 0px 0px 50px;
  color: #fff;
  font-size: 43px;
  font-weight: 700; }

.main-slider-item_info-desc {
  margin-bottom: 260px; }

.main-slider_btn-prev,
.main-slider_btn-next {
  width: 35px;
  height: 35px;
  background-color: red;
  cursor: pointer;
  top: 90% !important; }

.main-slider_btn-prev {
  background: url('../img/prev-arrow.png') no-repeat center;
  right: 120px !important;
  left: auto !important; }


.main-slider_btn-next {
  background: url('../img/next-arrow.png') no-repeat center;
  right: 60px !important; }


// Стили слайдера по умолчанию
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
    display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
    display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slider {
  position: relative;
  max-height: 300px;
  margin-bottom: 30px; }

.slick-prev, .slick-next {
  position: absolute;
  z-index: 2;
  top: 50%; }

.slick-prev {
  left: 20px; }

.slick-next {
  right: 20px; }

.slider-item img {
  border-radius: 4px; }

.slick-dots {
  display: flex;
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translate(-50%);
  margin: 0;
  padding: 0;
  list-style: none; }

.custom-slider-dots {
  width: 28px;
  height: 7px;
  background-color: #d4d4d4;
  margin-right: 8px;
  border-radius: 3px;
  opacity: 0.4;
  cursor: pointer; }

.slick-active > .custom-slider-dots {
  background-color: #fff;
  opacity: 0.9; }

// Стили слайдера по умолчанию конец

.heading-block {
  position: relative; }

.mainpage-header {
  width: 100%;
  max-width: 1680px;
  position: absolute;
  z-index: 3;
  right: 0;
  top: 10px; }

.main-header-info {
  display: flex;
  justify-content: flex-end;
  margin: 7px 50px 5px 0; }

.mainpage-header_block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105px;
  background-color: #fff;
  padding: 0 60px 0 20px; }

.main-header-info_phone {
  margin-right: 20px;
  font-size: 21px;
  color: #fff; }

.main-header-info_social {
  display: flex;
  align-items: center; }

.main-header-info_social-item {
  display: flex;
  margin-right: 10px; }

.mainpage-header_block-logo {
  display: flex;
  align-items: center;
  flex-grow: 1; }

.menu-block {
  display: flex;
  margin-right: 50px; }

.menu-item {
  display: flex;
  height: 105px;
  margin-right: 50px;
  align-items: center;
  position: relative;
  color: #373b3c;
  font-size: 18px;
  transition: all 0.4s;
  &:hover {
    color: #93a2bc; } }

.menu-item--active {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 6px;
    background-color: #93a2bc;
    top: 0; } }

.navigation {
  display: flex; }

.button {
  padding: 10px 20px;
  position: relative;
  border: 1px solid #93a2bc;
  color: #373b3c;
  font-size: 18px;
  transition: all 0.4s;
  &:hover {
    color: #93a2bc; }
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: -5px;
    top: calc(50% - 3px);
    height: 6px;
    width: 15px;
    background-color: #fff; }
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -8px;
    top: 50%;
    height: 1px;
    width: 15px;
    background-color: #93a2bc; } }

.button--fs14 {
  font-size: 14px; }

.info-about-company_description-block {
  padding: 30px 0 0 115px;
  background-image: url('../img/datchik.png');
  background-repeat: no-repeat; }

.first-block-description {
  max-width: 650px;
  color: #373b3c;
  font-size: 22px; }

.second-block-description {
  max-width: 650px;
  color: #373b3c;
  font-size: 16px;
  line-height: 26px; }

.info-about-company_action-title {
  max-width: 270px;
  min-height: 90px;
  color: #373b3c;
  font-size: 16px;
  font-style: italic;
  line-height: 22px;
  padding: 25px 0 0 25px;
  margin: 0;
  background-image: url('../img/66.png');
  background-repeat: no-repeat; }

.info-about-company_action {
  width: 100%;
  max-width: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.info-about-company {
  display: flex;
  justify-content: space-between; }

.quotes {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 255px;
  max-height: 420px;
  padding: 5px 10px 10px 10px;
  border: 1px solid #93a2bc; }

.quotes-title {
  color: #373b3c;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  border-bottom: 1px solid #93a2bc;
  padding-bottom: 5px;
  margin: 0 0 10px 0; }

.quotes-title-date,
.quotes-title-data {
  font-weight: 800; }


.quotes-table_name {
  width: 55%;
  color: #4e6691;
  font-size: 12px;
  padding: 4px 0 4px 0; }

.quotes-table_price {
  color: #373b3c;
  font-size: 12px;
  padding: 4px 0 4px 0; }

.quotes-table_price-neg {
  background-image: url('../img/negative.png');
  display: inline-flex;
  width: 9px;
  height: 7px;
  margin-right: 5px; }

.quotes-table_price-pos {
  background-image: url('../img/positive.png');
  display: inline-flex;
  width: 9px;
  height: 7px;
  margin-right: 5px; }

.quotes-link {
  color: #4e6691;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;
  margin: 20px 0 25px; }

.exchange-rates {
  border: 1px solid #93a2bc; }

.exchange-rates-title {
  margin-bottom: 7px;
  background-color: #93a2bc;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 2px 0 2px 0; }

.exchange-rates-usd,
.exchange-rates-eur {
  color: #4e6691;
  font-size: 28px;
  text-transform: uppercase;
  margin: 0; }

.exchange-rates-val {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px 15px; }

.exchange-rates-date {
  color: #373b3c;
  font-size: 12px;
  padding: 0;
  margin: 0; }
.exchange-rates-price {
  color: #373b3c;
  font-size: 12px;
  text-align: center;
  padding: 0;
  margin: 0; }

.exchange-rates-change-neg {
  color: #c52424;
  font-size: 12px;
  text-align: center;
  padding: 0;
  margin: 0; }

.exchange-rates-change-pos {
  color: #21cb60;
  font-size: 12px;
  text-align: center;
  padding: 0;
  margin: 0; }

.main-our-products {
  display: flex;
  justify-content: space-between; }

.main-our-products-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 180px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  padding: 15px 10px 20px;
  margin-bottom: 20px; }

.main-our-products-item-name {
  display: flex;
  align-items: center;
  color: #373b3c;
  font-size: 14px; }

.main-our-products-item-img {
  width: 100%;
  max-height: 130px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f1f1f1; }

.main-our-products-item-title {
  margin: 15px 0px 15px 10px; }

.button-more {
  color: #fff;
  background-color: #4e6691;
  border: 1px solid #4e6691;
  text-align: center;
  padding: 6px 15px;
  align-self: self-end;
  transition: all 0.4s;
  &:hover {
    color: #4e6691;
    background-color: #fff; } }


.main-our-products-items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.main-our-products_descr {
  flex-grow: 1;
  margin-left: 70px; }

.main-our-products_descr h2 {
  text-align: center;
  margin: 10px 0 25px; }

.main-our-products_text {
  margin-top: 30px;
  color: #373b3c;
  font-size: 16px;
  line-height: 26px; }

.main-our-adventure {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.main-our-adventure-item {
  display: flex;
  align-items: center;
  margin-top: 30px; }

.main-our-adventure-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 5px solid #4e6691;
  color: #373b3c;
  font-size: 28px;
  font-weight: 700; }

.main-our-adventure-text {
  width: 260px;
  position: relative;
  left: -10px;
  background-color: #fff;
  color: #373b3c;
  font-size: 16px; }

.main-our-adventure-number--fs36 {
  font-size: 36px; }

.still-questions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1340px;
  margin: 0 auto; }

.still-questions-text {
  max-width: 650px;
  min-height: 150px;
  color: #373b3c;
  font-size: 16px;
  line-height: 26px;
  background-image: url('../img/qm.png');
  background-repeat: no-repeat;
  padding: 30px 0 40px 60px; }

.confidence {
  display: flex;
  align-items: center;
  color: #373b3c;
  font-size: 12px;
  line-height: 16px;
  padding: 0 30px 0 0;
  a {
    color: #4e6691; } }

.form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 450px;
  justify-content: space-between; }

.form>.button {
  margin: 0 auto;
  align-self: baseline;
  padding: 10px 45px; }

.input {
  width: 100%;
  max-width: 215px;
  border: none;
  border-bottom: 1px solid #93a2bc;
  outline: none;
  &::placeholder {
    color: #727272;
    font-size: 16px;
    font-style: italic; } }

.container-input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.container-input input {
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer; }

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee; }

.container-input:hover input ~ .checkmark {
  background-color: #ccc; }

.container-input input:checked ~ .checkmark {
  background-color: #93a2bc; }

.container-input {
  &:after {
    content: "";
    position: absolute;
    display: block; } }


.container-input input:checked {
  .checkmark {}
  &:after {}
  display: block; }


.container-input {
  .checkmark {}
  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg); } }

.certificates-slider>.slick-list {
  height: 255px; }

.certificates-slider {
  a, img {
    width: 165px;
    height: 235px;
    margin-right: 20px;
    &:nth-child(even) {
      transform: translateY(20px); } } }


.certificates-slider_btn-prev {
  background: url('../img/sert-prev.png') no-repeat center;
  left: 45%; }

.certificates-slider_btn-next {
  background: url('../img/sert-next.png') no-repeat center;
  right: 48%; }

.certificates-slider_btn-next,
.certificates-slider_btn-prev {
  width: 35px;
  height: 35px;
  cursor: pointer;
  top: 120% !important; }

button.slick-prev.slick-arrow {
  display: none !important; }

button.slick-next.slick-arrow {
  display: none !important; }

.slick-lightbox-slick-item {
  outline: none; }

.mt150 {
  margin-top: 150px; }

.main-delivery {
  display: flex;
  justify-content: space-between; }

.main-delivery-text {
  max-width: 780px;
  min-height: 130px;
  color: #373b3c;
  font-size: 16px;
  line-height: 26px;
  background-image: url('../img/del.png');
  background-repeat: no-repeat;
  padding: 30px 0 40px 120px; }

footer {
  display: flex;
  height: 355px;
  background-color: #373b3c; }

.footer-block {
  display: flex;
  align-items: flex-start;
  margin-top: 30px; }

.footer-block-menu {
  display: flex;
  flex-direction: column; }

.footer-block-description {
  padding: 0 0 7px 0;
  margin: 0 20px 0 0;
  writing-mode: tb-rl;
  transform: rotate(-180deg);
  color: #fff;
  font-size: 16px;
  border-bottom: 3px solid #93a2bc; }

.footer-block-menu-item {
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
  transition: all 0.4s;
  &:hover {
    color: #93a2bc; } }

.footer-row {
  display: flex;
  justify-content: space-between; }

.footer-block-info {
  display: flex;
  flex-direction: column;
  color: #fff;
  font-size: 13px;
  a {
    color: #fff;
    margin: 0 0 15px 0; }
  p {
    margin: 0 0 15px 0;
    padding: 0; } }

.footer-block-info-time {
  margin-top: 30px; }

.button--color {
  color: #fff;
  &:before {
    background-color: #373b3c; } }

.footer-block-still-que {
  color: #fff;
  font-size: 13px;
  padding: 0;
  margin-bottom: 20px; }

.button--fs13 {
  font-size: 14px; }

.contribute {
  text-align: end; }

.footer-copyright {
  font-size: 13px; }

.footer-police {
  color: #fff; }

.footer-block-que {
  margin-top: 15px; }

.fg1 {
  flex-grow: 1; }

.fg05 {
  flex-grow: 0.5; }

.jc-fe {
  justify-content: flex-end; }

.default-header {
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  height: 80px; }

.default-header_block {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center; }

.default-header-logo {
  width: 170px; }

.default-header_block .menu-item {
  margin-right: 30px;
  height: 80px; }

.main-header-info_phone--color {
  color: #4e6691; }

.default-header_block .main-header-info_social {
  margin-right: 70px; }

.default-header_block .navigation {
  margin-right: 20px; }

.breadcrumbs {
  width: 100%;
  max-width: 1440px;
  margin: 15px auto 0;
  list-style: none;
  padding: 0;
  font-size: 14px;
  color: #373b3c;
  a {
    color: #373b3c; } }

.breadcrumbs__item {
  display: inline; }

.breadcrumbs__item::after {
  content: ">";
  position: relative;
  color: #4e6691;
  margin: 0 5px;
  font-size: 18px; }

.breadcrumbs__item--last::after {
  display: none; }

.breadcrumbs__item--here {
  position: relative;
  top: -1px; }

.breadcrumbs__item--penultimate a {
  position: relative;
  top: -1px; }

.breadcrumbs__item--penultimate a {
  color: #4e6691; }

.mt35 {
  margin-top: 35px; }

.catalog-block {
  display: flex;
  justify-content: space-between; }

.catalog-block-products {
  width: 100%;
  margin-left: 20px; }

.catalog-block-products {
  display: flex;
  flex-direction: column; }

.catalog-block-products-item-title h2 {
  margin: 0;
  padding: 0; }

.catalog-block-products-item {
  display: flex;
  margin-bottom: 40px; }

.catalog-block-products-item-description {
  color: #373b3c;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 25px; }

.catalog-block-products-item-title {
  display: flex;
  align-items: center;
  color: #373b3c;
  font-size: 20px;
  margin-bottom: 10px; }

.catalog-block-products-item-title p {
  margin: 0;
  padding: 0; }

.catalog-block-products-item-title img {
  margin-right: 5px; }

.catalog-block-products-item-img {
  min-width: 185px;
  max-height: 140px;
  padding: 10px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 14px;
  margin-right: 15px; }

.catalog-block-products-item-info {
  display: flex;
  flex-direction: column; }

.button-products-item {
  align-self: flex-end;
  color: #fff;
  font-size: 14px;
  background-color: #4e6691;
  padding: 7px 40px;
  transition: all 0.4s;
  border: 1px solid #4e6691;
  &:hover {
    background-color: #fff;
    color: #4e6691;
    border: 1px solid #4e6691; } }

.product-slider {
  margin-bottom: 60px; }

.product-slider img {
  width: 100%;
  max-height: 350px; }

.product-slider-nav_item {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 90px;
  margin: 0 20px 0 20px;
  border: 2px solid #f1f1f1;
  outline: none;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%; } }

.product-block-slider {
  max-width: 490px;
  margin-right: 80px; }

.product-slider_item {
  display: flex !important;
  justify-content: center;
  outline: none;
  cursor: grab; }

.product-slider-nav {
  margin: 0px -20px 0 -20px; }

.product-slider-nav_item.slick-slide.slick-current.slick-active {
  border-color: #93a2bc; }

.slick-dots {
  bottom: -40px; }

.slick-dots li button {
  text-indent: -9999px;
  width: 16px;
  height: 16px;
  background-color: #f1f1f1;
  border-radius: 100%;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 15px; }

.slick-dots li {
  &:last-child {
    margin: 0; } }

li.slick-active button {
  background-color: #93a2bc; }

.product-block {
  display: flex; }

.product-block-info {
  width: 100%; }

.product-block-info-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    padding: 0;
    margin: 0; } }

.product-block-info-title img {
  margin-right: 15px; }

.product-block-info-price {
  color: #4e6691;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px; }

.product-block-info-title {
  color: #373b3c;
  font-size: 18px;
  font-weight: 800; }

.product-block-info-text {
  color: #373b3c;
  font-size: 16px;
  margin-bottom: 20px; }

.product-block-info-descr {
  margin-bottom: 40px; }

.product-block-info-specifications {
  color: #373b3c;
  font-size: 16px;
  line-height: 24px;
  ul {
    list-style: none;
    padding-left: 15px;
    li {
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 8px;
        left: -15px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #93a2bc; } } } }

.button-download {
  color: #4e6691;
  font-size: 16px;
  padding: 14px 20px;
  border: 1px solid #4e6691;
  margin-right: 30px;
  transition: all 0.4s;
  &:hover {
    background-color: #4e6691;
    color: #fff; } }

.button-products-item--modifier {
  font-size: 22px;
  padding: 9px 75px 13px; }

[class*="ymaps-2"][class*="-ground-pane"] {
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'>;
    <filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale") {}
    /* Firefox 3.5+ */
    -webkit-filter: grayscale(100%);
    /* Chrome 19+ & Safari 6+ */ }

.m20 {
  margin: 20px auto 0; }

.contacts-information {
  position: absolute;
  z-index: 4;
  left: 235px;
  top: 60px;
  color: #373b3c;
  font-size: 18px;
  pointer-events: none; }

#map {
  height: 880px;
  width: 100%;
  position: relative; }

.contacts-information-block-item {
  color: #373b3c;
  font-size: 18px;
  a {
    color: #373b3c;
    font-size: 18px; }
  span {
    font-weight: 800; } }

.map-bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  pointer-events: none;
  background-image: -webkit-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.74) 70%, rgba(255, 255, 255, 0.88) 100%);
  background-image: -o-linear-gradient(right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.74) 70%, rgba(255, 255, 255, 0.88) 100%);
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.74) 70%, rgba(255, 255, 255, 0.88) 100%); }

.contacts-information-block-title {
  color: #373b3c;
  font-size: 18px;
  font-weight: 800;
  margin: 0 0 10px 0; }

.contacts-information-block {
  margin-bottom: 50px; }

.contacts-information-block-person {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  p {
    margin: 0 0 5px 0; }
  a {
    color: #373b3c;
    font-size: 18px;
    margin: 0 0 5px 0; } }

.contacts-information-block-person-group {
  width: 100%;
  max-width: 415px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.error-page-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1050px;
  margin: 50px auto 20px; }

.error-page-block-info {
  width: 100%;
  max-width: 475px; }

.error-page-block-info-descr {
  color: #373b3c;
  font-size: 18px;
  text-align: center;
  line-height: 30px; }

.err-button {
  color: #4e6691;
  font-size: 16px;
  padding: 15px 35px;
  border: 1px solid #4e6691; }

.button-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.block-typical-page {
  color: #373b3c;
  font-size: 16px;
  line-height: 26px;
  p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 15px 0; } }

.block-typical-images {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    width: 100%;
    max-width: 420px;
    max-height: 300px;
    margin-bottom: 20px; } }

.block-vacancy {
  width: 100%;
  max-width: 970px;
  color: #373b3c;
  font-size: 16px;
  line-height: 26px;
  p {
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 15px 0; } }

.block-vacancy-wrapper {
  background-image: url('../img/jobsbg.png');
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top; }

.block-vacancy-name {
  color: #373b3c;
  font-size: 22px;
  padding-left: 30px;
  margin-bottom: 10px; }

.plus-minus-toggle {
  cursor: pointer;
  height: 21px;
  position: absolute;
  width: 21px; }

.plus-minus-toggle {
  &:before {
    background: #4e6691;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 17px;
    transition: transform 500ms ease; } }

.plus-minus-toggle {
  &:after {
    background: #4e6691;
    content: '';
    height: 3px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 17px;
    transition: transform 500ms ease; } }

.plus-minus-toggle {
  &:after {
    transform-origin: center; } }

.plus-minus-toggle.collapsed {
  &:after {
    transform: rotate(90deg); } }

.plus-minus-toggle.collapsed {
  &:before {
    transform: rotate(180deg); } }

.block-vacancy-row {
  position: relative; }

.toggled-content {
  display: none; }
//pop-up start
.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .8);
  z-index: 10;
  display: none; }

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  max-width: 710px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 40px;
  box-sizing: border-box;
  background-color: #fff;
  color: #fff; }

.close-popup {
	position: absolute;
	top: 10px;
	right: 5px;
	width: 23px;
	height: 23px;
	cursor: pointer; }

.close-popup:before {
	content: '';
	background-color: #4e6691;
	position: absolute;
	height: 4px;
	width: 20px;
	top: 11px;
	left: -4px;
	transform: rotate(-45deg); }

.close-popup:after {
	content: '';
	background-color: #4e6691;
	position: absolute;
	height: 4px;
	width: 20px;
	top: 11px;
	transform: rotate(45deg);
	left: -4px; }
//pop-up end

.title-form {
  color: #373b3c;
  font-size: 28px;
  font-weight: 800;
  margin: 0 0 20px 0; }

.descr-form {
  color: #373b3c;
  font-size: 16px;
  margin: 0 0 40px 0; }

.ty-form {
  display: none;
  color: black;
  text-align: center; }

//button-menu
.button-menu {
  background: none;
  border: none;
  position: relative;
  height: 40px;
  width: 50px;
  cursor: pointer;
  outline: none; }

.line {
  position: absolute;
  height: 3px;
  width: 30px;
  background: #93a2bc;
  left: 10px;
  transition: 0.2s all ease; }

.line-t {
  top: 10px; }

.line-m {
  top: 20px; }

.line-b {
  top: 30px; }

.menu-on .line-m, .menu-on:hover .line-m {
  background: rgba(0,0,0,0); }

.menu-on .line {
  top: 20px; }

.menu-on .line-t {
  -webkit-transform: rotate(45deg); }

.menu-on .line-b {
  -webkit-transform: rotate(-45deg); }

.overlay-menu-block {
  display: none;
  width: 100%;
  height: calc(100vh - 50px);
  position: absolute;
  background-color: #fff;
  z-index: -1; }

.overlay-menu {
  ul {
    list-style: none;
    padding: 0;
    margin: 25px 0;
    li {
      margin-bottom: 25px;
      a {
        padding-left: 20px;
        display: block;
        color: #373b3c;
        font-size: 18px;
        position: relative; } } } }

.overlay-menu-item-active {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 24px;
    background-color: #93a2bc;
    left: 0; } }

.overlay-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-shadow: 0 1px 4px rgba(0,0,0,.16);
  .main-header-info_phone {
    color: #93a2bc;
    display: block; } }

.overlay-menu-contact-info {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; }
.overlay-menu {
  .main-header-info_social {
    margin-right: 0; } }

.contacts-wrapper {
  position: relative; }

.catalog-button-group {
  display: flex;
  flex-wrap: wrap; }
